import {
  List,
  Datagrid,
  TextField,
  EmailField,
  DeleteWithConfirmButton,
} from "react-admin";
import { Helmet } from "react-helmet";

export const AdminList = (props) => {
  return (
    <>
      <Helmet title={"Administradores"} />
      <List title={"Administradores"} {...props} bulkActionButtons={false}>
        <Datagrid rowClick={""}>
          <TextField source="name" label={"Nome"} />
          <EmailField source="email" />
          <DeleteWithConfirmButton label={"Deletar"} />
        </Datagrid>
      </List>
    </>
  );
};
