import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  BooleanField,
} from "react-admin";
import { Helmet } from "react-helmet";

export const CouponList = (props) => (
  <>
    <Helmet title={"Tickets"} />
    <List title={"Tickets"} {...props} bulkActionButtons={false}>
      <Datagrid rowClick={""}>
        <TextField source="token" />
        <DateField
          showTime
          locales={"pt-BR"}
          source="bookDate"
          label={"Data do agendamento"}
        />
        <ReferenceField
          source="customerId"
          reference="customers"
          label={"Cliente"}
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="redeemed" label={"Resgatado"} />
        <DateField
          showTime
          locales={"pt-BR"}
          source="createdAt"
          label={"Data da compra"}
        />
      </Datagrid>
    </List>
  </>
);

export default CouponList;
