import { useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  NumberInput,
  SelectInput,
  RadioButtonGroupInput,
  useQuery,
  DateTimeInput,
} from "react-admin";
import { Helmet } from "react-helmet";
import LinearProgress from "@material-ui/core/LinearProgress";

import httpClient from "../../config/httpClient";

export const SlotEdit = (props) => {
  const { data: slot } = useQuery({
    type: "getOne",
    resource: "slots",
    payload: { id: props.id },
  });

  const [services, setServices] = useState(null);
  useEffect(() => {
    if (slot) {
      httpClient(
        `${process.env.REACT_APP_API_URL}/services/${slot.serviceId}?fields=id,companyId`
      ).then((res) => {
        httpClient(
          `${process.env.REACT_APP_API_URL}/companies/${res.json.companyId}?fields=id,name&join=services||id,name`
        ).then((res) => setServices(res.json.services));
      });
    }
  }, [slot]);

  return (
    <>
      <Helmet title={"Edição de ticket"} />
      <Edit title={"Edição de ticket"} {...props}>
        <SimpleForm>
          <DateTimeInput
            label="Horário da vaga"
            source="startTime"
            parse={(v) => v}
          />
          <RadioButtonGroupInput
            source="discount"
            label="Desconto"
            choices={[
              { id: "0.50", name: "50%" },
              { id: "1.00", name: "100%" },
            ]}
          />
          <NumberInput source="availableSlots" label="Vagas disponíveis" />
          {services ? (
            <SelectInput
              source="serviceId"
              choices={services}
              label="Serviço"
            />
          ) : (
            <LinearProgress />
          )}
        </SimpleForm>
      </Edit>
    </>
  );
};
