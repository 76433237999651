import { List, SimpleList } from "react-admin";
import { Helmet } from "react-helmet";
import Avatar from "@material-ui/core/Avatar";

export const TagList = (props) => (
  <>
    <Helmet title={"Categorias"} />
    <List {...props} title={"Categorias"}>
      <SimpleList
        leftAvatar={(record) => <Avatar alt="" src={record.image} />}
        primaryText={(record) => record.name}
        linkType={"edit"}
      />
    </List>
  </>
);
