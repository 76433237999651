import { Create, SimpleForm, TextInput, PasswordInput } from "react-admin";
import { Helmet } from "react-helmet";

export const AdminCreate = (props) => (
  <>
    <Helmet title={"Criar Administrador"} />
    <Create {...props} title={"Criar Administrador"}>
      <SimpleForm>
        <TextInput source="name" label={"Nome"} />
        <TextInput type={"email"} source="email" />
        <PasswordInput source="password" label={"Senha"} />
      </SimpleForm>
    </Create>
  </>
);
