import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
  EmailField,
  DateField,
  Filter,
  TextInput,
  BooleanInput,
} from "react-admin";
import { Helmet } from "react-helmet";

const CompanyFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nome" source="name||$contL" alwaysOn />
    <TextInput label="Email" source="email||$contL" alwaysOn />
    <TextInput label="Cnpj" source="cnpj||$cont" alwaysOn />
    <BooleanInput label="Aprovada" source="approved||$eq" />
  </Filter>
);

export const CompanyList = (props) => (
  <>
    <Helmet title={"Salões"} />
    <List
      title={"Salões"}
      {...props}
      bulkActionButtons={false}
      filters={<CompanyFilter />}
    >
      <Datagrid rowClick="show">
        <TextField source="name" label={"Nome"} />
        <EmailField source="email" />
        <TextField source="cnpj" label={"CNPJ"} />
        <BooleanField source="approved" label={"Aprovada"} />
        <NumberField source="rating" label={"Avaliação Média"} />
        <DateField
          showTime
          locales={"pt-BR"}
          source="createdAt"
          label={"Data de criação"}
        />
      </Datagrid>
    </List>
  </>
);
