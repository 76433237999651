import * as React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { DashboardMenuItem, MenuItemLink, getResources } from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import AddAlertIcon from "@material-ui/icons/AddAlert";

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);

  return (
    <div style={{ paddingTop: 8 }}>
      {/* <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} /> */}
      {resources.map(
        (resource) =>
          (resource.hasCreate ||
            resource.hasEdit ||
            resource.hasList ||
            resource.hasShow) && (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) || resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )
      )}
      <MenuItemLink
        to="/criar-notificacao"
        primaryText="Criar Notificação"
        leftIcon={<AddAlertIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
