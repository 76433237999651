import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
} from "react-admin";
import { Helmet } from "react-helmet";

export const TagEdit = (props) => (
  <>
    <Helmet title={"Editar Categoria"} />
    <Edit {...props} title={"Editar Categoria"}>
      <SimpleForm>
        <ImageField source={"image"} label={"Imagem atual"} />
        <ImageInput source="image" label="Nova imagem" accept="image/*">
          <ImageField />
        </ImageInput>
        <TextInput source="name" label={"Nome"} />
      </SimpleForm>
    </Edit>
  </>
);
