import { Admin, Resource, Login, EditGuesser } from "react-admin";
import ptBrMessages from "ra-language-pt-br";
import polyglotI18nProvider from "ra-i18n-polyglot";

import BussinesIcon from "@material-ui/icons/Business";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SpaIcon from "@material-ui/icons/Spa";
import CategoryIcon from "@material-ui/icons/Category";
import { Route } from "react-router-dom";

import dataProvider from "./config/dataProvider";
import authProvider from "./config/authProvider";
import theme from "./config/theme";
import Layout from "./Layout";

import { CompanyList, CompanyShow } from "./Components/company";
import { CustomerList } from "./Components/customer";
import { CouponList } from "./Components/coupon";
import { AdminCreate, AdminList } from "./Components/administrator";
import { SlotEdit, SlotList } from "./Components/slot";
import { TagList, TagCreate, TagEdit } from "./Components/tag";
import { AmenityList, AmenityCreate, AmenityEdit } from "./Components/amenity";
import CreatePushNotification from "./Components/push-notification/Create";

const routes = [
  <Route exact path="/criar-notificacao" component={CreatePushNotification} />,
];

const messages = {
  "pt-br": ptBrMessages,
};

const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  "pt-br",
  { allowMissing: true }
);

const MyLoginPage = () => (
  <Login
    theme={theme}
    backgroundImage="https://source.unsplash.com/1600x900/daily?beauty-salon"
    style={{ backgroundSize: "cover", backgroundPosition: "center" }}
  />
);

function App() {
  return (
    <Admin
      customRoutes={routes}
      layout={Layout}
      loginPage={MyLoginPage}
      theme={theme}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
    >
      <Resource
        name={"companies"}
        options={{ label: "Salões" }}
        icon={BussinesIcon}
        list={CompanyList}
        show={CompanyShow}
      />
      <Resource
        name={"slots"}
        options={{ label: "Tickets à venda" }}
        icon={ShoppingBasketIcon}
        list={SlotList}
        edit={SlotEdit}
      />
      <Resource
        name={"customers"}
        options={{ label: "Clientes" }}
        list={CustomerList}
        icon={PermContactCalendarIcon}
      />
      <Resource
        name={"coupons"}
        options={{ label: "Tickets vendidos" }}
        list={CouponList}
        icon={ConfirmationNumberIcon}
      />
      <Resource
        name={"administrators"}
        list={AdminList}
        icon={AssignmentIndIcon}
        create={AdminCreate}
        options={{ label: "Administradores" }}
      />
      <Resource
        name={"tags"}
        icon={CategoryIcon}
        list={TagList}
        create={TagCreate}
        edit={TagEdit}
        options={{ label: "Categorias" }}
      />
      <Resource
        name={"amenities"}
        icon={SpaIcon}
        list={AmenityList}
        create={AmenityCreate}
        edit={AmenityEdit}
        options={{ label: "Comodidades" }}
      />
      <Resource name={"company-addresses"} />
      <Resource name={"cities"} />
      <Resource name={"states"} />
      <Resource name={"services"} />
    </Admin>
  );
}

export default App;
