import {
  List,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  ReferenceField,
} from "react-admin";
import { Helmet } from "react-helmet";

export const CustomerList = (props) => (
  <>
    <Helmet title={"Clientes"} />
    <List title={"Clientes"} {...props} bulkActionButtons={false}>
      <Datagrid rowClick={""}>
        <TextField source="name" label={"Nome"} />
        <TextField source="phone" label={"Telefone"} />
        <EmailField source="email" />
        <DateField
          showTime
          locales={"pt-BR"}
          source="createdAt"
          label={"Data de criação"}
        />
        <ReferenceField
          source="cityId"
          reference="cities"
          link={false}
          label={"Cidade"}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="cityId"
          reference="cities"
          link={false}
          label={"Estado"}
        >
          <ReferenceField reference={"states"} source={"stateId"} link={false}>
            <TextField source={"name"} />
          </ReferenceField>
        </ReferenceField>
      </Datagrid>
    </List>
  </>
);
