import nestjsxCrudDataProvider from "ra-data-nestjsx-crud";
import omitBy from "lodash.omitby";
import { stringify } from "querystring";
import { fetchUtils } from "ra-core";
import { CondOperator, RequestQueryBuilder } from "@nestjsx/crud-request";

import httpClient from "./httpClient";

const apiUrl = process.env.REACT_APP_API_URL;
const dataProvider = nestjsxCrudDataProvider(apiUrl, httpClient);

const dataProviderWithFileUpload = {
  ...dataProvider,
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { q: queryParams, ...filter } = params.filter || {};

    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter: composeFilter(filter),
    })
      .setLimit(perPage)
      .setPage(page)
      .sortBy(params.sort)
      .setOffset((page - 1) * perPage);

    if (params.filter && encodedQueryFilter) {
      composeJoinQuery(encodedQueryFilter, params.filter);
    }

    const query = mergeEncodedQueries(
      encodedQueryParams,
      encodedQueryFilter.query()
    );

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      total: json.total,
    }));
  },
  create: (resource, params) => {
    const hasFile = checkIfHasFileAndSetFormData(params);

    let body;
    if (hasFile) body = params.data;
    else body = JSON.stringify(params.data);

    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body,
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },
  update: (resource, params) => {
    params.data = omitBy(params.data, (v, k) => params.previousData[k] === v);
    const hasFile = checkIfHasFileAndSetFormData(params);

    let body;
    if (hasFile) body = params.data;
    else body = JSON.stringify(params.data);

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PATCH",
      body,
    }).then(({ json }) => ({ data: json }));
  },
};

export default dataProviderWithFileUpload;

function composeJoinQuery(queryBuilder, filter) {
  const flatFilter = fetchUtils.flattenObject(filter);
  Object.keys(flatFilter).forEach((filterKey) => {
    if (filterKey.includes(".")) {
      queryBuilder.setJoin({ field: filterKey.split(".")[0], select: ["id"] });
    }
  });
}

function composeFilter(paramsFilter) {
  const flatFilter = fetchUtils.flattenObject(paramsFilter);
  return Object.keys(flatFilter).map((key) => {
    const splitKey = key.split("||");

    let field = splitKey[0];
    let ops = splitKey[1];
    if (!ops) {
      if (
        typeof flatFilter[key] === "number" ||
        flatFilter[key].match(/^\d+$/)
      ) {
        ops = CondOperator.EQUALS;
      } else {
        ops = CondOperator.CONTAINS;
      }
    }

    if (field.startsWith("_") && field.includes(".")) {
      field = field.split(/\.(.+)/)[1];
    }
    return { field, operator: ops, value: flatFilter[key] };
  });
}

function composeQueryParams(queryParams = {}) {
  return stringify(fetchUtils.flattenObject(queryParams));
}

function mergeEncodedQueries(...encodedQueries) {
  return encodedQueries.map((query) => query).join("&");
}

function checkIfHasFileAndSetFormData(params) {
  const dataKeys = Object.keys(params.data);
  let hasFile = !!dataKeys.find(
    (dataKey) =>
      params.data[dataKey].rawFile &&
      params.data[dataKey].rawFile instanceof File
  );
  if (hasFile) {
    const formData = dataKeys.reduce((formDataAcc, dataKey) => {
      const data = params.data[dataKey];
      formDataAcc.append(
        dataKey,
        typeof data !== "string" && "rawFile" in data ? data.rawFile : data
      );
      return formDataAcc;
    }, new FormData());

    params.data = formData;
  }

  return hasFile;
}
