import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Show,
  TabbedShowLayout,
  Tab,
  BooleanField,
  FunctionField,
  NumberField,
  EmailField,
  ImageField,
  TopToolbar,
  TextField,
  UrlField,
  Confirm,
  useUpdate,
  useQuery,
} from "react-admin";
import Button from "@material-ui/core/Button";

import httpClient from "../../config/httpClient";

const CompanyShowActions = ({ data }) => {
  const [approve, { loading }] = useUpdate(
    "companies",
    data ? data.id : "",
    {
      approved: true,
    },
    data
  );

  const [openDialog, setOpenDialog] = useState(false);
  return (
    <TopToolbar>
      {data && !data.approved && (
        <>
          <Button
            color="primary"
            onClick={() => setOpenDialog(true)}
            disabled={loading}
          >
            Aprovar
          </Button>
          <Confirm
            isOpen={openDialog}
            loading={loading}
            title="Aprovar Salão"
            content="Deseja prosseguir?"
            onConfirm={approve}
            onClose={() => setOpenDialog(false)}
          />
        </>
      )}
    </TopToolbar>
  );
};

const CompanyTitle = ({ record }) => {
  return <span>Salão {record ? `${record.name}` : ""}</span>;
};

export const CompanyShow = (props) => {
  const { data, loading } = useQuery({
    type: "getList",
    resource: "company-addresses",
    payload: {
      pagination: { page: 1, perPage: 1 },
      filter: { "companyId||$eq": props.id },
    },
  });

  const [city, setCity] = useState(null);
  useEffect(() => {
    if (data && data[0]) {
      httpClient(
        `${process.env.REACT_APP_API_URL}/cities?filter=id||$eq||${data[0].cityId}`
      ).then(({ json }) => {
        setCity(json[0]);
      });
    }
  }, [data]);

  const [state, setState] = useState(null);
  useEffect(() => {
    if (city) {
      httpClient(
        `${process.env.REACT_APP_API_URL}/states?filter=id||$eq||${city.stateId}`
      ).then(({ json }) => {
        setState(json[0]);
      });
    }
  }, [city]);

  return (
    <>
      <Helmet title={"Visualização de salão"} />
      <Show
        title={<CompanyTitle />}
        actions={<CompanyShowActions />}
        {...props}
      >
        <TabbedShowLayout>
          <Tab label={"Básico"}>
            <ImageField source="logo" />
            <BooleanField source="approved" label={"Aprovado?"} />
            <TextField source="name" label={"Nome"} />
            <TextField source="cnpj" label={"CNPJ"} />
            <NumberField source="rating" label={"Avaliação"} />
            <EmailField source="email" label={"Email"} />
            <UrlField source="website" label={"Site"} />
            <UrlField source="instagram" />
            <UrlField source="facebookUrl" label={"Facebook"} />
            <TextField source="about" label={"Sobre"} />
            <TextField source="phone" label="Telefone" />
            <TextField source="opensAt" label={"Horário de abertura"} />
            <TextField source="closesAt" label={"Horário de fechamento"} />
            <ImageField source="banner" />
            <TextField source="createdAt" label={"Data de Criação"} />
            <TextField source="updatedAt" label={"Ultima Atualização"} />
          </Tab>
          <Tab label={"Endereço"} path={"endereco"}>
            <FunctionField
              render={() => (data ? data[0].street : "")}
              label={"Rua"}
            />
            <FunctionField
              render={() => (data ? data[0].number : "")}
              label={"Número"}
            />
            <FunctionField
              render={() => (data ? data[0].neighborhood : "")}
              label={"Bairro"}
            />
            <FunctionField
              render={() => (data ? data[0].cep : "")}
              label={"CEP"}
            />
            <FunctionField
              render={() => (data ? data[0].complement || "" : "")}
              label={"Complemento"}
            />
            <FunctionField
              render={() => (city ? city.name : "")}
              label={"Cidade"}
            />
            <FunctionField
              render={() => (state ? state.name : "")}
              label={"Estado"}
            />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};
