import { Create, SimpleForm, TextInput } from "react-admin";
import { Helmet } from "react-helmet";

export const AmenityCreate = (props) => (
  <>
    <Helmet title={"Criar Comodidade"} />
    <Create {...props} title={"Criar Comodidade"}>
      <SimpleForm>
        <TextInput source="name" label={"Nome"} />
      </SimpleForm>
    </Create>
  </>
);
