import { List, SimpleList } from "react-admin";
import { Helmet } from "react-helmet";

export const AmenityList = (props) => (
  <>
    <Helmet title={"Comodidades"} />
    <List {...props} title={"Comodidades"}>
      <SimpleList primaryText={(record) => record.name} linkType={"edit"} />
    </List>
  </>
);
