import { Edit, SimpleForm, TextInput } from "react-admin";
import { Helmet } from "react-helmet";

export const AmenityEdit = (props) => (
  <>
    <Helmet title={"Editar Comodidade"} />
    <Edit {...props} title={"Editar Comodidade"}>
      <SimpleForm>
        <TextInput source="name" label={"Nome"} />
      </SimpleForm>
    </Edit>
  </>
);
