import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
} from "react-admin";
import { Helmet } from "react-helmet";

export const TagCreate = (props) => (
  <>
    <Helmet title={"Criar Categoria"} />
    <Create {...props} title={"Criar Categoria"}>
      <SimpleForm>
        <ImageInput source="image" label="Imagem de fundo" accept="image/*">
          <ImageField />
        </ImageInput>
        <TextInput source="name" label={"Nome"} />
      </SimpleForm>
    </Create>
  </>
);
