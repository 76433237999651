import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  DateField,
  FunctionField,
  Filter,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { Helmet } from "react-helmet";

const SlotFilter = (props) => (
  <Filter {...props}>
    <DateInput
      label="De"
      source="startTime||$gte"
      format={(v) => {
        if (v) {
          const startTimeFrom = new Date(v);
          return `${startTimeFrom.getUTCFullYear()}-${(
            startTimeFrom.getUTCMonth() + 1
          )
            .toString()
            .padStart(2, "0")}-${startTimeFrom
            .getUTCDate()
            .toString()
            .padStart(2, "0")}`;
        }
        return v;
      }}
      parse={(v) => {
        const startTimeFrom = new Date(v);
        startTimeFrom.setUTCHours(0);
        startTimeFrom.setUTCMinutes(0);
        startTimeFrom.setUTCSeconds(0);
        startTimeFrom.setUTCMilliseconds(0);
        return startTimeFrom.toISOString();
      }}
      alwaysOn
    />
    <DateInput
      label="Até"
      source="startTime||$lte"
      alwaysOn
      format={(v) => {
        if (v) {
          const startTimeTo = new Date(v);
          return `${startTimeTo.getUTCFullYear()}-${(
            startTimeTo.getUTCMonth() + 1
          )
            .toString()
            .padStart(2, "0")}-${startTimeTo
            .getUTCDate()
            .toString()
            .padStart(2, "0")}`;
        }
        return v;
      }}
      parse={(v) => {
        const startTimeTo = new Date(v);
        startTimeTo.setUTCHours(23);
        startTimeTo.setUTCMinutes(59);
        startTimeTo.setUTCSeconds(59);
        startTimeTo.setUTCMilliseconds(59);
        return startTimeTo.toISOString();
      }}
    />
    <ReferenceInput
      label="Empresa"
      alwaysOn
      source="service.companyId||$eq"
      reference="companies"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const SlotList = (props) => (
  <>
    <Helmet title={"Tickets à venda"} />
    <List
      title={"Tickets à venda"}
      {...props}
      bulkActionButtons={false}
      filters={<SlotFilter />}
    >
      <Datagrid rowClick="edit">
        <ReferenceField
          source="serviceId"
          reference="services"
          label="Empresa"
          link={false}
        >
          <ReferenceField
            source="companyId"
            reference="companies"
            link={(record) => `/companies/${record.companyId}/show`}
          >
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField
          source="serviceId"
          reference="services"
          label="Serviço"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField label="Horário da vaga" source="startTime" showTime />
        <FunctionField
          label="Desconto"
          render={(record) => `${Math.round(record.discount * 100)}%`}
        />
        <NumberField source="availableSlots" label="Vagas disponíveis" />
      </Datagrid>
    </List>
  </>
);
