import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Helmet } from "react-helmet";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <>
    <Helmet
      titleTemplate={"%s - Beauticket Administratrivo"}
      defaultTitle="Beauticket Administratrivo"
    />
    <CssBaseline />
    <App />
  </>,
  document.getElementById("root")
);

