import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import red from "@material-ui/core/colors/red";

const theme = merge({}, defaultTheme, {
  palette: {
    primary: { main: "#CC2727" },
    secondary: { main: "#A06464" },
  },
  typography: {
    fontFamily: [
      "Exo 2",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

export default theme;
