import React, { useState } from "react";
import { Title } from "react-admin";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import httpClient from "../../config/httpClient";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));
const Create = () => {
  const classes = useStyles();
  const [form, setForm] = useState({
    topic: "customers",
    notification: { title: "", body: "" },
  });
  const [loading, setLoading] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    httpClient(`${process.env.REACT_APP_API_URL}/notification`, {
      method: "POST",
      body: JSON.stringify(form),
    })
      .then(() => setForm({ ...form, notification: { title: "", body: "" } }))
      .finally(() => setLoading(false));
  };

  return (
    <Card style={{ marginTop: 16 }}>
      <Helmet title="Criar notificação" />
      <Title title="Criar notificação" />

      <CardContent>
        <Typography variant="h5" component="h1">
          Criar notificação
        </Typography>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <TextField
            inputProps={{ required: true }}
            label="Titulo"
            value={form.notification.title}
            onChange={(e) =>
              setForm({
                ...form,
                notification: { ...form.notification, title: e.target.value },
              })
            }
          />
          <TextField
            label="Mensagem"
            inputProps={{ required: true }}
            multiline
            value={form.notification.body}
            onChange={(e) =>
              setForm({
                ...form,
                notification: { ...form.notification, body: e.target.value },
              })
            }
          />

          <FormControl component="fieldset">
            <FormLabel component="legend">Público alvo</FormLabel>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="customers"
              value={form.topic}
              onChange={(e) => setForm({ ...form, topic: e.target.value })}
            >
              <FormControlLabel
                value={"customers"}
                control={<Radio color="primary" />}
                label="Clientes"
              />
              <FormControlLabel
                value={"companies"}
                control={<Radio color="primary" />}
                label="Estabelecimentos"
              />
              <FormControlLabel
                value={"all"}
                control={<Radio color="primary" />}
                label="Todos"
              />
            </RadioGroup>
          </FormControl>

          <Button
            disabled={
              loading || !form.notification.body || !form.notification.title
            }
            variant="contained"
            color="primary"
            type="submit"
          >
            {loading ? "Enviando, aguarde..." : "Enviar"}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default Create;
